<template>
  <div>
    <p class="msg">
      <van-icon
        size=".34rem"
        @click="fh"
        style="transform: translateY(10px)"
        name="arrow-left"
      />
      <span class="wan">工作履历</span>
      <span class="span"  @click="bc">保存</span>
    </p>
    <div class="inresume">
      <p>
        <span class="ss">企业名称</span
        ><input
          v-model="from[0].company_name"
          type="text"
          placeholder="请输入企业名称"
          style="text-align: right"
        />
      </p>
      <p>
        <span class="ss" @click="show = true">入职时间</span
        ><input
          readonly="readonly"
          type="text"
          placeholder="请选择入职时间"
          v-model="from[0].entry_time"
          @click="show = true"
          style="text-align: right"
        />
      </p>
      <p>
        <span class="ss" @click="show1 = true">离职时间</span
        ><input
          readonly="readonly"
          type="text"
          placeholder="请选择离职时间"
          v-model="from[0].quit_time"
          @click="show1 = true"
          style="text-align: right"
        />
      </p>
      <p>
        <span class="ss" @click="zw">工作岗位</span
        ><input
          readonly="readonly"
          :disabled="$route.params.id != null"
          @click="zw"
          type="text"
          v-model="from[0].position"
          placeholder="请选择工作"
          style="text-align: right"
        />
      </p>
      <p style="border-bottom: 0px none">
        <span class="ss">工作履历</span>
      </p>

      <textarea
        name=""
        placeholder="完善的履历更容易获得优质企业关注哦！"
        v-model="from[0].working_experience"
        id=""
      ></textarea>
      <!-- <p style="border-bottom: 0px none">
        <span>本人职责</span>
      </p>
      <textarea
        name=""
        placeholder="填写工作履历"
        v-model="from[0].responsibilities"
        id=""
      ></textarea> -->
      <!-- <div @click="del" class="sc" v-if="$route.params.id">删除</div> -->
      <!-- <div @click="bc" class="sc" v-if="!$route.params.id">保存</div> -->
    </div>
    <van-action-sheet v-model="show" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="qd"
          @cancel="show = false"
        />
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="show1" title="">
      <div class="content">
        <van-datetime-picker
          v-model="currentDate1"
          type="date"
          title="选择年月日"
          :min-date="minDate1"
          :max-date="maxDate1"
          @confirm="qd1"
          @cancel="show1 = false"
        />
      </div>
    </van-action-sheet>
    <van-popup v-model="showg" position="bottom" :style="{ height: '100%' }" >
      <zww @config="configg" @concel="concel"></zww>
    </van-popup>
  </div>
</template>

<script>
import zww from './zw.vue'
export default {
  components:{
    zww
  },
  created() {
    console.log(this.$route.params);
    console.log(this.$route.params.id);
    

    if (this.$route.params.item) {
      console.log(2132132);
      this.$route.params.item = JSON.parse(this.$route.params.item);
      this.from[0].entry_time = this.$route.params.item.entry_time;
      this.from[0].quit_time = this.$route.params.item.quit_time;
      this.from[0].position = this.$route.params.item.position;
      this.from[0].company_name = this.$route.params.item.company_name;
      this.from[0].working_experience = this.$route.params.item.working_experience;
      this.from[0].responsibilities = this.$route.params.item.responsibilities;
    } else {
      let arr = JSON.parse(localStorage.getItem("wz"));
      if (arr) {
        this.from[0].entry_time = arr[0].entry_time;
        this.from[0].quit_time = arr[0].quit_time;
        this.from[0].position = arr[0].position;
        this.from[0].company_name = arr[0].company_name;
        this.from[0].working_experience = arr[0].working_experience;
        this.from[0].responsibilities = arr[0].responsibilities;
      }
    }
    if(localStorage.getItem("gws")){
      this.from[0].position = localStorage.getItem("gws");
    }
  },
  data() {
    return {
      showg:false,
      show: false,
      show1: false,
      from: [
        {
          id: Date.parse(new Date()),
          company_name: "", //企业名称
          entry_time: "", //入职
          quit_time: "", //离职
          position: "", //工作职位
          working_experience: "", //工作履历
          responsibilities: "", //
        },
      ],
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      minDate1: new Date(2000, 0, 1),
      maxDate1: new Date(),
      currentDate1: new Date(),
    };
  },
  methods: {
    concel(){
      this.showg = false;
    },
    configg(e){
      this.from[0].position = e;
      this.showg = false;
    },
    //保存履历
    bc() {
      if (this.from[0].company_name == "") {
        this.$toast.fail("企业名称不能为空");
        return;
      }
      if (this.from[0].entry_time == "") {
        this.$toast.fail("入职时间不能为空");
        return;
      }
      if (this.from[0].quit_time == "") {
        this.$toast.fail("离职时间不能为空");
        return;
      }
      if (this.from[0].entry_time > this.from[0].quit_time) {
        this.$toast.fail("入职时间不能高于离职时间");
        return;
      }
      if (this.from[0].position == "") {
        this.$toast.fail("工作职位不能为空");
        return;
      }
      if (this.from[0].working_experience == "") {
        this.$toast.fail("工作履历不能为空");
        return;
      }
      let arr;
      if (this.$route.params.id != undefined) {
        console.log(222222222222222222222222);
        arr = JSON.parse(localStorage.getItem("list"));
        arr.forEach((item, index) => {
          if (item.id == this.$route.params.id) {
            arr[index] = this.from[0];
            console.log(arr);
          }
        });
        localStorage.setItem("list", JSON.stringify(arr));
        this.$router.push("/resume2");
        return;
      } else {
        if (localStorage.getItem("list") == "") {
          localStorage.removeItem("list");
        }
        arr = JSON.parse(localStorage.getItem("list"));
        console.log("ppppppppppppppppp" + arr);
        arr = arr == undefined ? [] : arr;
        arr.push(this.from[0]);
        // arr = arr[arr.length] = this.from[0];
        localStorage.removeItem("wz");
        localStorage.setItem("list", JSON.stringify(arr));
        this.$router.push("/resume2");
        return;
      }
    },
    //返回过往履历页面
    fh() {
      localStorage.removeItem("wz");
      this.$router.push("/resume2");
    },
    //删除本条履历
    del() {
      let arr = JSON.parse(localStorage.getItem("list"));
      localStorage.removeItem("wz");
      arr = arr.filter((item) => {
        return item.id != this.$route.params.id;
      });
      arr = JSON.stringify(arr);
      console.log(arr);
      localStorage.setItem("list", arr);
      this.$router.push("/resume2");
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    //获取入职时间
    qd(val) {
      let aa = new Date(val);
      this.from[0].entry_time =
        aa.getFullYear() + "-" + (aa.getMonth() + 1) + "-" + aa.getDate();
      this.show = false;
    },
    //获取离职时间
    qd1(val) {
      let aa = new Date(val);
      this.from[0].quit_time =
        aa.getFullYear() + "-" + (aa.getMonth() + 1) + "-" + aa.getDate();
      this.show1 = false;
    },
    //选择工作岗位缓存本条履历
    zw() {
      this.showg = true
    },
  },
  destroyed(){
    localStorage.removeItem("gws");
  }
};
</script>

<style scoped lang='less'>
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
input:disabled {
  background-color: #fff;
}

.sc {
  width: 6.86rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
}
.msg {
  line-height: 0.88rem;
  border-bottom: solid 0.01rem #eeeeee;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  padding: 0 .2rem;
}

.msg .wan {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.inresume {
  padding: 0 0.2rem;
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.46rem;
    border-bottom: 1px solid #ccc;
    span {
      font-size: 0.3rem;
      width: 1.5rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #697386;
    }
    input {
      outline: none;
      border: 0 none;
      font-size: 0.3rem;
      width: 5rem;
    }
  }
  textarea {
    resize: none;
    outline: none;
    font-size: 0.3rem;
    width: 100%;
    height: 4rem;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
  }
}
.span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
</style>